<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-col cols="6" align="right" class="py-0">
        <v-btn
          icon
          elevation="0"
          v-on="on"
          @click="
            alert.type = null;
            alert_economia = null;
          "
        >
          <v-icon large>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </template>
    <v-card id="createAlerts">
      <v-card-title>
        {{ $t("new", { name: $tc("alert") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="back" v-if="alert.type != null">
        <v-btn icon @click="alert_economia = null" small>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center" v-if="alert.type == null">
          <v-col cols="8" md="4" v-for="(at, i) in alert_types" :key="i">
            <base-border-card
              :title="$tc(alert_types[i], 1)"
              @card_clicked="alert.type = alert_types[i]"
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row
          id="customer_select"
          v-if="alert.type == 'ECONOMÍA' && alert_economia == null"
          justify="center"
        >
          <div class="back">
            <v-btn icon @click="alert.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>

          <v-col cols="8" md="6">
            <base-border-card
              :title="'ARTISTA'"
              @card_clicked="
                alert_economia = 'Artista';
                alert.sub_type = 'Artista';
              "
            >
            </base-border-card>
          </v-col>

          <v-col cols="8" md="6">
            <base-border-card
              :title="'CLIENTE'"
              @card_clicked="
                alert_economia = 'Cliente';
                alert.sub_type = 'Cliente';
              "
            >
            </base-border-card>
          </v-col>
        </v-row>

        <v-row v-if="alert.type == 'SESIÓN'">
          <div class="back">
            <v-btn icon @click="alert.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
        </v-row>

        <v-row v-if="alert.type == 'OTROS'">
          <div class="back">
            <v-btn icon @click="alert.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>

          <v-col class="pb-2">
            <v-text-field
              outlined
              dense
              v-model="alert.title"
              :label="$t('alerts.title')"
              data-vv-validate-on="blur"
              hide-details
              class="semidense"
            ></v-text-field>
          </v-col>
        </v-row>

        <form
          action
          data-vv-scope="alert-form"
          @submit.prevent="validateForm('alert-form')"
        >
          <v-row
            v-if="
              (alert.type == 'ECONOMÍA' && alert_economia == 'Artista') ||
                alert_economia == 'Cliente' ||
                alert.type == 'SESIÓN' ||
                alert.type == 'OTROS'
            "
            style="position: relative"
            align="center"
          >
            <v-col cols="12" md="6" lg="6" class="py-md-0 pb-0 pt-2">
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="fecha"
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$clock' : ''"
                    outlined
                    hide-details
                    dense
                    required
                    v-validate="'required'"
                    data-vv-name="startDate"
                    :error-messages="errors.collect('alert-form.startDate')"
                    :label="$t('alerts.date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="fecha"
                  />
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="alert.startDate"
                  no-title
                  scrollable
                  color="primary"
                  :min="today"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-row class="px-3" align="center">
              <v-col
                cols="5"
                sm="3"
                md="3"
                lg="3"
                class="py-0 pr-md-0 pt-2 pr-lg-3"
              >
                <v-autocomplete
                  :items="hours"
                  :disabled="alert.all_day"
                  v-model="alert.startTime"
                  :label="$t('alerts.startTime')"
                  dense
                  outlined
                  hide-details
                  data-vv-validate-on="blur"
                  v-validate="alert.all_day ? '' : 'required'"
                  data-vv-name="startTime"
                  :error-messages="errors.collect('alert-form.startTime')"
                ></v-autocomplete>
              </v-col>
              <p
                class="pa-1 mb-0"
                style="display: inline-block; text-align: center"
              >
                -
              </p>
              <v-col
                cols="6"
                sm="3"
                md="3"
                lg="3"
                class="py-0 pt-2 pl-md-0 pl-lg-3"
              >
                <v-autocomplete
                  :items="hours"
                  :disabled="alert.all_day"
                  v-model="alert.endTime"
                  :label="$t('alerts.endTime')"
                  hide-details
                  dense
                  data-vv-validate-on="blur"
                  v-validate="alert.all_day ? '' : 'required'"
                  data-vv-name="endTime"
                  :error-messages="errors.collect('alert-form.endTime')"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="4" class="py-0 pt-2">
                <v-switch
                  v-model="alert.all_day"
                  hide-details
                  class="py-0 mt-0"
                >
                  <template v-slot:label>
                    <div class="no-wrap">{{ $t("alert.allDay") }}</div>
                  </template>
                </v-switch>
              </v-col>
            </v-row>

            <!-- <div
            v-if="hoursSelected(alert.startTime, alert.endTime) != 0"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'position: absolute; right: 5px;'
                : 'position: absolute; right: 30px;'
            "
            class=""
          >
            {{ hoursSelected(alert.startTime, alert.endTime) }}h
          </div> -->

            <v-col cols="12" md="12">
              <v-textarea
                outlined
                hide-details
                auto-grow
                :label="$t('alerts.description')"
                dense
                rows="2"
                v-model="alert.description"
                style="border-radius:10px"
              ></v-textarea
            ></v-col>
          </v-row>

          <v-card-actions
            v-if="
              alert.type == 'OTROS' ||
                alert.type == 'SESIÓN' ||
                alert_economia != null
            "
          >
            <v-spacer />
            <v-btn
              outlined
              @click="dialog = false"
              style="height: 25px; width: 100px"
              elevation="0"
              class="mr-1"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              elevation="0"
              type="submit"
              style="
                    width: 100px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
              class="ml-2"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: $t("") }) }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import * as moment from "moment";

export default {
  name: "AlertNew",
  props: ["appointment"],
  computed: {
    fecha() {
      if (this.alert.startDate) {
        return this.$d(new Date(this.alert.startDate), "dateShort");
      }
      return "";
    },

    hours() {
      let mins = [":00", ":15", ":30", ":45"];
      let hours = [];
      let first = 0;
      let last = 23;
      for (let x = first; x <= last; x++) {
        mins.forEach((y) => {
          if (x < 10) hours.push("0" + x + y);
          else hours.push(x + y);
        });
      }
      return hours;
    },
  },
  data() {
    return {
      artist_selected: null,
      client_selected: null,
      dialog: false,
      searchCustomer: "",
      alert_types: ["ECONOMÍA", "SESIÓN", "OTROS"],
      alert: {
        appointment_id: null,
        type: null,
        sub_type: null,
        startDate: null,
        startTime: null,
        endTime: null,
        all_day: false,
        title: "",
        description: null,
      },
      alert_economia: null,
      today: null,
      menu: false,
    };
  },
  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },
  methods: {
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomers() {
      console.log("load", this.isLoadingCustomer);
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
        loading: false,
      }).then((response) => {
        this.customers = response.data;
        this.isLoadingCustomer = false;
      });
    },
    hoursSelected(start, end) {
      let mins = ["00", "15", "30", "45"];
      let first = parseInt(start);
      let last = parseInt(end) + 1;
      let count = 0;
      if (start && end) {
        let s = new Date();
        s.setHours(start.split(":")[0], start.split(":")[1]);
        let e = new Date();
        e.setHours(end.split(":")[0], end.split(":")[1]);

        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            let now = new Date();
            now.setHours(x, y);
            if (now.getTime() < e.getTime() && now.getTime() >= s.getTime())
              count++;
          });
        }
      }
      return count / 4;
    },

    ...mapActions("alerts", ["addAlert", "getAlertsById"]),
    async validateForm(scope) {
      this.errors.clear();
      console.log("validando");
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          console.log("SAVE ALERT");
          console.log(this.alert);
          this.alert.appointment_id = this.appointment.id;
          this.alert.title = this.title();
          this.addAlert(this.alert).then((response) => {
            this.dialog = false;
            this.$emit("alert-added", response.data);
            console.log(response.data);
            this.fetchAlertsById();
          });
        }
        this.$emit("update");
      });
    },

    fetchAlertsById() {
      console.log("FETCH ALERTS");
      this.getAlertsById(this.appointment.id).then((response) => {
        console.log(response);
        this.alerts = response;
      });
    },
    title() {
      console.log("TITILO CREADO");
      switch (this.alert.type) {
        case "ECONOMÍA":
          console.log("ECONOMÍA");
          if (this.alert.sub_type == "Artista") {
            return "Recuerda pagar al Artista";
          }
          return "Recuerda cobrar al Cliente";
        case "SESIÓN":
          console.log("SESIÓN");
          return "Recuerda añadir una nueva sesión a la cita";
        case "OTROS":
          console.log("OTROS");
          return this.alert.title;
        default:
          break;
      }
    },
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
};
</script>
<style lang="sass">

#createAlerts
  padding: 0px 35px 0
  .v-input .v-input__prepend-outer .v-icon
    font-size: 20px
    height: 20px


  button.btn_toggle
    height: 30px !important
    border-color: var(--v-primary-base) !important
    background-color: transparent

    &.v-btn--active
      background-color: var(--v-primary-base) !important
      color: var(--v-gris3-base) !important
</style>
